export default function Faq() {
  return (
    <>
      <div id="faq" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-gray-100"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-300">
            If you have anything else you want to ask,
            <a
              className="text-primary underline ml-2"
              href="mailto:hello@mendable.ai"
            >
              reach out to us
            </a>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          <li>
            <ul role="list" className="space-y-10">
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  Is it free?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  We have a free plan that gives you 500 message credits. It is
                  also free for certain Open source projects. Contact us to see
                  if your project is eligible.
                </p>
              </li>
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  Do you train your AI model with my code?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Currently, Mendable does not look at any of your repository's
                  code. However, in the future we may add it. We will always
                  give you the option to opt out of sharing your data.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" className="space-y-10">
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  How do I remove the Powered by Mendable?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  To remove the Powered by Mendable, you need to upgrade to an
                  enterprise or custom plan. Contact us at{' '}
                  <a className="underline" href="mailto:eric@mendable.ai">
                    eric@mendable.ai
                  </a>{' '}
                  and we can help you out.
                </p>
              </li>
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  How do I get an anon key?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  To get your anon key you need to sign up at{' '}
                  <a className="underline" href="https://mendable.ai">
                    mendable.ai
                  </a>{' '}
                  and create a project. Then you can find your anon key in the
                  API Keys section of the dashboard. Anon keys are used for
                  client-side while API keys are used for server-side.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" className="space-y-10">
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  Is there a free plan?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Mendable offers a free plan with 300 message credits. It is
                  also free for certain Open source projects. Contact us to see
                  if your project is eligible.
                </p>
              </li>
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  Is GPT-4 pricing different?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Yes, right now GPT-4 will cost 3 requests per message instead
                  of 1 (gpt-3.5-turbo). That means that instead of 500 messages,
                  you will get around 166 messages if you only use GPT-4.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" className="space-y-10">
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  Can you correct the AI response?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Yes, Mendable offers a 'teach the model' functionality where
                  you can correct the AI response and it will learn from it.
                </p>
              </li>
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  How can I integrate Mendable with my application?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Probably! Check out the Mendable documentation here{' '}
                  <a className="underline" href="https://docs.mendable.ai">
                    https://docs.mendable.ai
                  </a>{' '}
                  to better understand how you can start integrating.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" className="space-y-10">
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  Is it 100% accurate?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Like Humans, AI will never be 100% accurate. So we can't
                  assure you that every solution will be correct.
                </p>
              </li>
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  How do I cancel my subscription?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Simply log into our platform, go to your account and click on
                  "Open customer portal" button. There you will be able to
                  cancel/modify it through Stripe.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" className="space-y-10">
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  How does Mendable work?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Our application syncs with your documentation and support
                  channels, then uses your docs and previously answered
                  questions to suggest possible answers.
                </p>
              </li>

              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  Are you open-source?
                </h3>
                <p className="mt-4 text-sm text-gray-200">
                  Currently not - although we have some open source components
                  and integrations. If you have input here, please message us
                  at.
                  <a
                    className="text-primary underline ml-2"
                    href="mailto:hello@mendable.ai"
                  >
                    hello@mendable.ai
                  </a>
                  .
                </p>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" className="space-y-10">
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-100">
                  How does Mendable price custom plans?
                </h3>
                <div className="mb-6">
                  <h4 className="text-base font-semibold mb-4">1. Use case</h4>
                  <ul className="list-disc pl-5">
                    <li className="mb-2">
                      Mendable differentiates between internal and external use
                      cases.
                    </li>
                    <li>
                      With Mendable, we give you the ability to use our chat
                      bots for a variety of use cases, both for internal
                      efficiency and external communication to your customers.
                    </li>
                  </ul>
                </div>

                {/* 2. Total Usage */}
                <div className="mb-6">
                  <h4 className="text-base font-semibold mb-4">
                    2. Total usage
                  </h4>
                  <ul className="list-disc pl-5">
                    <li className="mb-2">
                      For specifically external use cases, you will only pay for
                      the value you're receiving.
                    </li>
                    <li>
                      Mendable will look at the total number of messages sent
                      during a month.
                    </li>
                  </ul>
                </div>

                {/* 3. Custom Work */}
                <div>
                  <h4 className="text-base font-semibold mb-4">
                    3. Custom work
                  </h4>
                  <ul className="list-disc pl-5">
                    <li>
                      If there are any special feature requests (custom data
                      connectors, etc.), we are happy to discuss these
                      requirements!
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
}
